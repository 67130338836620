<template>
  <div class="changetel">
    <!-- 头部 -->

    <Navbar
      :title="phone ? '更换手机号' : '绑定手机号'"
      background="white"
      needBack
      :needRight="false"
    />
    <div class="phone-main">
      <template v-if="phone">
        <h3 class="phone-name">原手机号码</h3>

        <input
          placeholder="请输入手机号"
          disabled
          v-model="phone"
          type="text"
          class="phone-input"
        />
      </template>

      <h3 class="phone-name phone-name-top">
        {{ phone ? "绑定新手机号码" : "绑定手机号码" }}
      </h3>

      <input
        placeholder="请输入手机号"
        :maxlength="11"
        @input="handleInput"
        v-model="newPhone"
        type="tel"
        class="phone-input"
      />

      <div class="verify">
        <input
          type="tel"
          v-model="sms"
          placeholder="请输入短信验证码"
          class="verify-input"
        />
        <van-button
          class="verify-btn"
          :disabled="disabled"
          @click.prevent="getCode($event)"
          type="default"
          v-if="showtime === null"
          >获取验证码</van-button
        >
        <van-button v-else class="verify-btn" :disabled="true" type="default">{{
          showtime
        }}</van-button>
      </div>
    </div>
    <p class="bottom-btn">
      <van-button class="save-btn" @click.native="sureEdit">确定</van-button>
    </p>
  </div>
</template>

<script>
import { NavBar, Field } from "vant";
// import Heador from '@/components/public/header';

let accessToken = localStorage.getItem("token");
export default {
  data() {
    return {
      phone: JSON.parse(localStorage.userInfo).mobile,
      newPhone: null,
      sms: null,
      showtime: null,
      disabled: true,
      options: {
        leftcname: "icon-fanhui-copy",
        rightcname: " ",
      },
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    // Heador
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后再获取`;
    },
    // 倒计时 60秒 不需要很精准
    countDown(times) {
      if (times) this.showtime = "发送中";
      const self = this;
      // 时间间隔 1秒
      const interval = 1000;
      let count = 0;
      self.timeCounter = setTimeout(countDownStart, interval);
      // 定时器中的方法
      function countDownStart() {
        if (self.timeCounter == null) {
          return false;
        }
        count++;
        self.countDownText(times - count + 1);
        if (count > times) {
          self.showlogin = true;
          clearTimeout(self.timeCounter);
          self.showtime = null;
        } else {
          self.timeCounter = setTimeout(countDownStart, interval);
        }
      }
    },
    // 获取验证码
    getCode(event) {
      // 点击太过频繁
      this.countDown(60);
      console.log(localStorage.userInfo);
      if (event.target.disabled) {
        this.countDown(0);
        return false;
      }
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.newPhone)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        this.countDown(0);
        return false;
      } else {
        this.$api.login
          .getCode({
            mobile: this.newPhone,
          })
          .then((res) => {
            if (res.code == 200) {
              // 调用倒计时
              // this.countDown(60);
              // 测试验证码
              this.sms = res.data.code;
            }
          });
      }
    },
    handleInput() {
      console.log("变色");
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.newPhone)) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // 确定修改
    sureEdit() {
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.newPhone)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        return false;
      } else {
        this.$api.my
          .changePhone({
            id: localStorage.id,
            mobile: this.phone,
            code: this.sms,
            mobileNew: this.newPhone,
          })
          .then((res) => {
            if (res.code == 200) {
              let userInfo = JSON.parse(localStorage.userInfo);
              console.log(userInfo);
              userInfo.mobile = this.newPhone;
              localStorage.userInfo = JSON.stringify(userInfo);
              this.$toast({
                message: "修改成功",
                onClose: () => {
                  this.$router.replace("/information");
                },
              });
            } else {
              this.$toast(res.errmsg);
            }
          });
      }
    },
  },
};
</script>

<style lang='less' scoped>
@red: #c83f3f;
.changetel {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: #f5f7fb;
  .van-nav-bar {
    // margin: 0 0 0.3rem;
    .van-icon {
      color: #ccc;
    }
    .van-nav-bar__title {
      color: @red;
    }
  }
  .van-cell {
    padding: 0.4rem;
    // margin-left: 5%;
    // margin: 0 0.2rem;
  }
  & > p {
    text-align: center;
    // margin: 0.5rem 0 0;
  }
}
// .dark_shop_bg {
//   // width: 90%;
// }
.bottom-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.3rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.2rem 0px rgba(7, 63, 53, 0.1);
  .save-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6.3rem;
    height: 0.9rem;
    background: linear-gradient(0deg, #3b5bff, #7399f5);
    border-radius: 0.45rem;

    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.9rem !important;
  }
}
.phone-main {
  padding: 0.5rem 0.3rem;
}
.phone-name {
  margin-bottom: -0.14rem;
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 0.49rem;
}
.phone-input {
  padding: 0.3rem;
  width: 100%;
  height: 0.9rem;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 0.1rem;
  box-sizing: border-box;

  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.4rem;

  &::-webkit-input-placeholder {
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #aeaeae;
    line-height: 0.4rem;
  }
}
.phone-name-top {
  margin-top: 0.58rem;
}
.verify {
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;

  .verify-input {
    padding: 0.3rem;
    box-sizing: border-box;

    width: 4.2rem;
    height: 0.9rem;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 0.1rem;

    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.4rem;
    &::-webkit-input-placeholder {
      font-size: 0.28rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #aeaeae;
      line-height: 0.4rem;
    }
  }
  .verify-btn {
    margin-left: 0.3rem;
    width: 2.4rem;
    height: 0.9rem;
    // background: #FFFFFF;
    // border: 1px solid #EFEFEF;
    // color: #AEAEAE;
    font-size: 0.28rem;
    background: linear-gradient(0deg, #3b5bff, #7399f5);
    color: #fff;
    border-radius: 0.1rem;

    font-family: PingFang SC;
    font-weight: 500;
    line-height: 0.4rem;
  }
}
</style>
